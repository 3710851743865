<template>
  <div class="flex-box vertical content">
    <div class="data-title">
      <div class="rec-title-text">共{{ pagination.total }}条记录</div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item>
          <a-range-picker
            v-model="form.time"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList"
            allowClear
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="form.type"
            :options="typeOptions"
            placeholder="选择身份筛选"
            allowClear
            style="width: 200px"
            @change="updateList"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="form.rule_id"
            :options="ruleOptions"
            placeholder="选择规则筛选"
            allowClear
            style="width: 200px"
            @change="updateList"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="form.check_status"
            :options="checkinStatusOptions"
            placeholder="选择状态筛选"
            allowClear
            style="width: 200px"
            @change="updateList"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入姓名/法名查询" allowClear></a-input>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "数据导出" }}
        </a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "../../../common/js/tool";
import { checkinStatusOptions, typeOptions, getTypeText } from "./rule";
import { getTemple } from "../../../common/js/storage";

export default {
  name: "CheckinRecord",
  data() {
    return {
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "日期", dataIndex: "date" },
        {
          title: "姓名/法名",
          customRender: (text) => {
            return <span>{text.eUser?.name}</span>;
          },
        },
        {
          title: "部门",
          customRender: (text) => {
            return <open-data class="name-txt" type="departmentName" openid={text.eUser?.dept_id} />;
          },
        },
        {
          title: "身份",
          customRender: (text) => {
            return <span>{getTypeText(text.eUser?.type)}</span>;
          },
        },
        {
          title: "最早打卡时间",
          dataIndex: "start_check_time",
          customRender: (text) => {
            return <span>{text ? new Date(text).pattern("HH:mm") : "-"}</span>;
          },
        },
        {
          title: "最晚打卡时间",
          dataIndex: "end_check_time",
          customRender: (text) => {
            return <span>{text ? new Date(text).pattern("HH:mm") : "-"}</span>;
          },
        },
        {
          title: "所属规则",
          customRender: (text) => {
            let rule_snap = text.rule_snap;
            let rule = rule_snap && JSON.parse(rule_snap);
            return <span>{rule?.name || "-"}</span>;
          },
        },
        {
          title: "状态",
          customRender: (text) => {
            let start_check_status = text.start_check_status;
            let end_check_status = text.end_check_status;
            let statusText = "-";
            if (start_check_status == 1 && end_check_status == 1) {
              statusText = "正常";
            } else {
              statusText = "异常";
            }
            return <span>{statusText}</span>;
          },
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      checkinStatusOptions,
      typeOptions,
      ruleOptions: null,
      searchKeyType: {
        type: 2,
        name: 2,
        check_status: 2,
        time: function(form, key) {
          let customUrl = "";
          let value = form[key];
          if (value && value.length == 2) {
            let start = value[0] + " 00:00:00";
            let end = value[1] + " 23:59:59";
            customUrl += `&start_time=${start}&end_time=${end}`;
          }
          return customUrl;
        },
      },
      exporting: false,
    };
  },
  created() {
    this.getRuleOptions();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    getRuleOptions() {
      this.$axios({
        url: "/admin/checkin-rule",
        method: "GET",
      }).then((res) => {
        this.ruleOptions = res.data?.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));
      });
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let url = `/admin/checkin-record?sort=-id&expand=eUser,rule`;
      let temple = getTemple();
      if (temple) {
        url += `&temple_id=${temple}`;
      }
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios({ url, noTempleFilter: true })
        .then((res) => {
          this.loading = false;
          let list = res.data;
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    getQuery(ex) {
      let query = "";
      let form = this.form;
      let searchKeyType = this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            if(ex) {
              query += `&${key}=${form[key]}`;
            } else {
              query += `&filter[${key}]=${form[key]}`;
            }
          }
        }
      }
      return ex ? query.replace("&", "?") : query;
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let fileName = "考勤记录";
      let url = "/admin/checkin-record/export" + this.getQuery(true);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          this.exporting = false;
          const content = res;
          const blob = new Blob([content]);
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-calendar-picker {
  width: 240px;
  text-align: left;
}
.ant-select {
  width: 120px;
}
.ant-input-affix-wrapper {
  width: 180px;
}
</style>
